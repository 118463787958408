<template>
  <div class="page events-page">
    <div v-if="showFilter" class="row filter">
      <div class="col typo">
        <button-elem
          @click="onClickFilter1($event)"
          :class="{ 'is-primary': isFilter1, 'is-outline': !isFilter1 }"
          class="is-medium">
            Veranstaltungen
        </button-elem>
        <button-elem
          @click="onClickFilter2($event)"
          :class="{ 'is-primary': isFilter2, 'is-outline': !isFilter2 }"
          class="is-medium">
            Kurse
        </button-elem>
        <hr />
      </div>
    </div>
    <events-elem
      :events="events"
      :emptyResult="$store.state.page.content.emptyresult"
      :isLoading="isLoading">
    </events-elem>
    <div class="row">
      <div class="col text">
        <hr />
        <text-elem :text="$store.state.page.content.eventstext"></text-elem>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/services/API'
import EventsElem from '@/components/elements/Events'

export default {
  name: 'events-page',
  components: {
    EventsElem
  },
  data () {
    return {
      head: {},
      events: [],
      filter: 0,
      isLoading: true
    }
  },
  created () {
    if (this.showFilter) {
      this.filter = 1
    }
    this.getEvents()
  },
  computed: {
    showFilter () {
      return this.$store.state.page.content.showfilter.value
    },
    isFilter1 () {
      return this.showFilter && this.filter === 1
    },
    isFilter2 () {
      return this.showFilter && this.filter === 2
    }
  },
  methods: {
    onClickFilter1 (Event) {
      this.filter = 1
      this.getEvents()
    },
    onClickFilter2 (Event) {
      this.filter = 2
      this.getEvents()
    },
    getEvents () {
      var request = {
        order: 'asc',
        filter: [ 'dateend.gte.today' ]
      }
      if (this.showFilter) {
        if (this.filter === 1) {
          request.filter.push('blueprint.nt.event_course')
        } else if (this.filter === 2) {
          request.filter.push('blueprint.eq.event_course')
        }
      }
      this.isLoading = true
      API.children(this.$route.path, request)
        .then((response) => {
          this.head = response.data.head
          this.events = response.data.children
          this.isLoading = false
        })
        .catch((reason) => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="sass">
.events-page
  .filter
    .col
      display: flex
      justify-content: center
      flex-wrap: wrap
      .button
        margin: 0 m(1) m(3) m(1)
  .event
    margin-bottom: m(6)
    &:last-child
      margin-bottom: 0
    .info
      padding-left: col-gap('lg')
      .datetime
        margin-top: m(1)
        .date
          +font('medium')
      .speaker
        margin-top: m(1)
        width: 80%
      .button
        margin-top: m(3)
  .emptyresult
    .col
      text-align: center
      hr
        margin: m(3) 0
      *
        +font('medium')
  .spinner
    margin: m(2) 0

+md
  .events-page
    .event
      .info
        padding-left: col-gap('md')

+sm
  .events-page
    .event
      .info
        padding-left: col-gap('sm')

+xs
  .events-page
    .event
      .info
        padding-left: col-gap('xs') / 2
</style>